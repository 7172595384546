import * as React from "react"
import Layout from "../../components/layout"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Helmet from 'react-helmet'


const qvm = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'single-page whitemain'
    }}
/>
  <Layout pageTitle="qvm">
    <section id="main">
      <div id="worktitle">
        <span>
          <h1>Queen Victoria Market Night Market</h1>
          <span className="highlight"></span>
        </span>
        <span>Queen Victoria Market</span>
      </div>
    </section>

    <section id="work">
      <div className="work">
          <div className="picture workpicture">
		  	<img src={'/img/works/project-featured-qvm-nightmarket2.jpg'} alt="QVM NIGHT MARKET" />

          </div>

        <div className="seeWork">
          <div className="seeWork__content">
            <div className="icons">
              <img className="picture" src={'/img/single/wordpress-icon.png'} width="139" height="87" alt="Wordpress" />
            </div>
            <a className="button visitwebsite" href="https://thenightmarket.com.au/" target="_blank" rel="noreferrer"><span className="seemore"><i>&#xe801;</i></span>Visit Website</a> 

          </div>

        </div>

      </div>
    </section>

    <section id="single-content">
      <div className="single-content">
        <p>Queen Victoria Market Night Market brings the tastes, sounds and sights of the Orient to Queen Victoria Market.</p>
        <p>Collaborating with Queen Victoria Market’s designer to deliver re-skinned and improvement WordPress site and responsive for desktop, smartphone and tablet with customised vendors and events management system.</p>
      </div>

      <div className="project-image">
        <div className="left-col">
          <img className="picture" src={'/img/single/thenightmarketcomau.jpg'} alt="Desktop View" />
        </div>
        <div className="right-col">
          <img className="picture" src={'/img/single/thenightmarketcomau_mobile.jpg'} alt="Mobile View" />
      </div>
      </div>

    </section>


    <section id="post-pagination">
      <div className="post-pagination">
        <div className="previous">
          <AniLink cover to="/work/sports-excellence-scholarship-funds" bg="#00ccd4" direction="right" duration={0.76} className="button project prev">Prev Work</AniLink>
          <p className="project-name">SESF</p>
        </div>
        <div className="next">
          <AniLink cover to="/work/bmg" bg="#00ccd4" direction="right" duration={0.76} className="button project next">Next Work</AniLink>
          <p className="project-name">BMG</p>
        </div>
      </div>
    </section>
</Layout>

  </>
  )
}

export default qvm


export const Head = () => (
  <>
    <title>QVM Winter and Summer Night Market - Tobstar</title>
<meta name="description" content="Winter and Summer Night Market brings the tastes, sounds and sights of the Orient to Queen Victoria Market." />
  </>
)